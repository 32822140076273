.btn, .btn[type="submit"], .btn[type="button"] {
    @apply bg-yellow font-bold text-lg px-8 py-2 uppercase rounded relative;
}

    .btn::after {
        content: '';
        display: block;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 0 13px 13px;
        border-color: transparent transparent #00000020 transparent;
        position: absolute;
        right: .25rem;
        bottom: .25rem;
        z-index: 2;
    }

.btn-blue {
    @apply bg-blue text-white font-bold px-4 py-2 uppercase rounded;
}

.btn-red {
    @apply bg-red-danger text-white font-bold px-4 py-2 uppercase rounded;
}
