@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Barlow+Condensed:wght@300;400;500;600&family=Barlow:wght@300;400;600&display=swap');

@layer base {
    html, body {
        font-family: 'Barlow Condensed', sans-serif;
    }
}

@import './css/toggle-switch.css';
@import './css/buttons.css';
@import './css/forms.css';
@import './css/animations.css';
