.toggle-switch {
    @apply flex flex-row items-center;
}

.toggle-switch-bg {
    @apply bg-white rounded-xl w-[34px] h-[18px] relative ml-2;
}

    .toggle-switch-bg:after {
        content: '';
        @apply absolute top-0.5 left-0.5 bg-gray-300 rounded-full w-[14px] h-[14px] transition;
    }

#toggle-switch-input:checked + .toggle-switch-bg:after {
    transform: translateX(110%);
    @apply bg-red-light;
}
